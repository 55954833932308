<template>

  <b-card-actions ref="tree_card" title="" action-refresh @refresh="refresh">
  <b-row>
    <b-col
        cols="3"
        md="3"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >


      <b-button
          variant="primary"
          class="button-inline-spacing-2 mb-1"
          @click="add_section()"
          :disabled="editDisabled"
      >
        <span class="text-nowrap">{{$t("Add Section")}}</span>
      </b-button>
    </b-col>
    <b-col
        cols="9"
        md="9"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <!-- search input -->
      <b-input-group class="mb-1">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon"/>
        </b-input-group-prepend>
        <b-form-input
            v-model.lazy="searchword"
            placeholder="Search..."
            @input="search"
        />
      </b-input-group>

    </b-col>

  </b-row>
  <b-row><!-- tree -->
    <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0 ml-1"
    > <v-tree
        ref="tree"
        :can-delete-root="true"
        :data="sections"
        :draggable="true"
        :halfcheck="true"
        :tpl="tpl"
        ondragend="dragEnd(dragNode, targetNode)"
    /></b-col>

  </b-row>



  </b-card-actions>
</template>

<script>


import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BRow, BCol, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";


import {VTree} from 'vue-tree-halower'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {codeSearch} from '../../extensions/tree/code'
import {BCardActions} from "@core/components/b-card-actions";
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import i18n from "@/libs/i18n";
import ability from "@/libs/acl/ability";
import EquipmentService from "@/basic/equipmentService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from "vue-good-table";

export default {
  name: "DocumentSectionTree",
  components: {
    VTree,
    BCardCode,
    BCardActions,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      codeSearch,
      searchword: '',
      children: [],
      treeData: [
        {
          title: i18n.t('Document sections'),
          expanded: true,
          children: this.children
        },
      ],
      sections: [],
      editDisabled: !ability.can("manage", "Basic Library"),
    }
  },

  methods: {
    refresh() {
      console.log("sections (tree) refresh")
      this.$refs["tree_card"].showLoading = true

      const userService = new UserService(useJwt)
      let company_id = store.state.userCompany.id;
      let parent_id = this.$route.params.parent_id ? this.$route.params.parent_id : 0;


      userService.document_sections_tree(company_id, {}).then(response => {
        this.sections = response.data.data.tree
        this.sections.forEach((element, index) => {


        });
        console.log('sections (tree)', this.sections)
        console.log('rows (tree)', this.rows)
        this.$refs["tree_card"].showLoading = false
      })

    },
    add_section() {
      this.$router.push({name: 'document-section', params: {id: 'new'}})
    },
    edit(id) {
      console.log('edit', id)
      this.$router.push({name: 'document-section', params: {id: id}})
    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    tpl(...args) {
      const {0: node, 2: parent, 3: index} = args
      console.log('tpl node', node);
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
      if (node.searched) titleClass += ' node-searched'

      if (node.id === -1){
        return <span>
        <span class={titleClass} domPropsInnerHTML={""} onClick={() => {
          this.$refs.tree.nodeSelected(node)
        }}></span>

      </span>
      }
      else if (node.children.length === 0){
        return <span>
        <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
          this.$refs.tree.nodeSelected(node)
        }}></span>

        <button class="btn-success text-success border-0 cursor-pointer" onClick={() => this.list_documents(node.id)}><feather-icon
            icon="ListIcon"/></button>
        <button class="btn-success text-success border-0 cursor-pointer" onClick={() => this.edit(node.id)}><feather-icon
            icon="EditIcon"/></button>
        <button class="btn-delete text-danger border-0 cursor-pointer" onClick={() => this.deleteSection(node)}>      <feather-icon
            icon="TrashIcon"/>
</button>
      </span>
      }
      else{
        return <span>
        <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
          this.$refs.tree.nodeSelected(node)
        }}></span>

        <button class="btn-success text-success border-0 cursor-pointer" onClick={() => this.list_documents(node.id)}><feather-icon
            icon="ListIcon"/></button>
        <button class="btn-success text-success border-0 cursor-pointer" onClick={() => this.edit(node.id)}><feather-icon
            icon="EditIcon"/></button>
      </span>
      }

    },
    async dragEnd(node, target) {
      console.log("dragged",node)
      console.log("target",target)
    },
    deleteSection(row) {
      console.log('deleteSection')
      let confirmed = false;
      let message = i18n.t('You are deleting the document section') + ": \"" + row.title + "\" " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const userService = new UserService(useJwt)

              userService.delete_document_section(row.id).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('Document Section deleted'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: i18n.t('Document Section deleted with success'),
                  },
                })
                this.refresh();
              })
            }
          })

    },

  },
  mounted() {
    const userService = new UserService(useJwt)

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    this.refresh();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
}
</script>
