<template>


<div>
  <document-section-tree />

</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BRow, BCol
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ability from "@/libs/acl/ability";
import DocumentSectionTree from "@/views/table/vue-good-table/DocumentSectionTree";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    DocumentSectionTree,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      columns: [
        {
          label: 'name',
          field: 'name',
        },
        {
          label: 'description',
          field: 'description',
        },

        {
          label: 'action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('name'),
          field: 'name',
        },
        {
          label: i18n.t('description'),
          field: 'description',
        },


      ],
      rows: [],
      searchTerm: '',
      editDisabled: !ability.can("manage", "Basic Library"),
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true

      const userService = new UserService(useJwt)
      let company_id = store.state.userCompany.id;
      let parent_id = this.$route.params.parent_id?this.$route.params.parent_id:0;


      userService.document_sections(company_id, {}).then(response => {
        this.rows = response.data.data
        this.rows.forEach((element, index) => {
          if (this.rows[index].document_section_id > 0){
            this.rows[index].id = this.rows[index].document_section_id;
          }

        });
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false
      })

    },


    add_section() {
      this.$router.push({name: 'document-section', params: {id: 'new'}})
    },
    edit(id) {
      this.$router.push({name: 'document-section', params: {id: id}})
    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },

  mounted() {
    const userService = new UserService(useJwt)
    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    this.refresh();


    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>
